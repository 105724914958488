import { makeAutoObservable } from "mobx";
import RunescapeStore from "./runescape";

export class RootStore {
	public runescape: RunescapeStore;

	constructor() {
		this.runescape = new RunescapeStore();
		this.runescape.start();
		makeAutoObservable(this);
	}
}

const store = new RootStore();
export default store;
