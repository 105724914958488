import 'whatwg-fetch';

type RequestHeaders = Record<string, string | string[]>;

interface IRequestOptions {
	baseUrl?: string;
	headers?: RequestHeaders;
}

export class API {
	public headers: RequestHeaders;
	protected baseUrl: string;

	public constructor(opts: IRequestOptions) {
		this.baseUrl = opts.baseUrl || '';
		this.headers = opts.headers || {};
	}

	public get(path: string, qs?: object, headers: RequestHeaders = {}) {
		return fetch(this.parseURL(path, qs), {method: 'GET', headers: this.parseHeaders(headers)})
			.then(this.handleResponse).catch(this.handleError);
	}

	public delete(path: string, qs?: object, headers: RequestHeaders = {}) {
		return fetch(this.parseURL(path, qs), {method: 'DELETE', headers: this.parseHeaders(headers)})
			.then(this.handleResponse).catch(this.handleError);
	}

	public post(path: string, body?: object, headers: RequestHeaders = {}, qs?: object) {
		headers['Content-Type'] = 'application/json';
		return fetch(this.parseURL(path, qs), {method: 'POST', headers: this.parseHeaders(headers), body: body ? JSON.stringify(body) : null})
			.then(this.handleResponse).catch(this.handleError);
	}

	public put(path: string, body?: object, headers: RequestHeaders = {}, qs?: object) {
		headers['Content-Type'] = 'application/json';
		return fetch(this.parseURL(path, qs), {method: 'PUT', headers: this.parseHeaders(headers), body: body ? JSON.stringify(body) : null})
			.then(this.handleResponse).catch(this.handleError);
	}

	public patch(path: string, body?: object, headers: RequestHeaders = {}, qs?: object) {
		headers['Content-Type'] = 'application/json';
		return fetch(this.parseURL(path, qs), {method: 'PATCH', headers: this.parseHeaders(headers), body: body ? JSON.stringify(body) : null})
			.then(this.handleResponse).catch(this.handleError);
	}

	public BaseURL(): string {
		return this.baseUrl;
	}

	private handleResponse(res: Response) {
		return res.text().then((data) => {
			if(data.length > 0) return JSON.parse(data);
			return data;
		});
	}

	private handleError(err: any) {
		return Promise.reject(new RequestError(err.code || 500, err.message ? err.message.toString() : "An unknown error occurred."));
	}

	private parseURL(path: string, qs?: object): string {
		let url = `${this.BaseURL()}${path}`;
		if(qs) url += `?${Object.entries(qs).map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`).join('&')}`;
		return url;
	}

	private parseHeaders(headers: RequestHeaders): Headers {
		const h: Headers = new Headers();
		for(const key in this.headers) {
			if(Array.isArray(this.headers[key])) {
				(this.headers[key] as string[]).forEach(value => h.append(key, value));
				continue;
			}
			h.append(key, this.headers[key] as string);
		}

		for(const key in headers) {
			if(Array.isArray(headers[key])) {
				(headers[key] as string[]).forEach(value => h.append(key, value));
				continue;
			}
			h.append(key, headers[key] as string);
		}
		return h;
	}
}

export class RequestError extends Error {
	public statusCode: number;

	public constructor(status: number, message: string) {
		super(message);
		this.statusCode = status;
	}
}

export default API;
