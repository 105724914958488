import React from 'react';
import { Box, Paper, Stack, Typography } from '@mui/material';
import { Tooltip } from 'recharts';

interface ITooltipProps {
	active?: boolean;
	payload?: { name: string; dataKey: string; value: number; stroke: string }[];
	label?: string;
}

interface IDotProps {
	hover?: boolean;
	active?: string;
	cx?: number;
	cy?: number;
	payload?: { name: string };
	stroke?: string;
}

const ChartTooltip = Tooltip;
const ChartTooltipContent: React.FunctionComponent<ITooltipProps> = ({ active, payload }) => {
	if(!active) {
		return null;
	}
  
	return (
		<Paper sx={{ border: '1px solid var(--mui-palette-divider)', boxShadow: 'var(--mui-shadows-16)', p: 1 }}>
			<Stack spacing={2}>
				{payload?.map(
					(entry): React.JSX.Element => (
						<Stack direction="row" key={entry.name} spacing={3} sx={{ alignItems: 'center' }}>
							<Stack direction="row" spacing={1} sx={{ alignItems: 'center', flex: '1 1 auto' }}>
							<Box sx={{ bgcolor: entry.stroke, borderRadius: '2px', height: '8px', width: '8px' }} />
							<Typography sx={{ whiteSpace: 'nowrap' }}>{entry.name}</Typography>
							</Stack>
							<Typography color="text.secondary" variant="body2">
								{entry.value.toLocaleString()}
							</Typography>
						</Stack>
					)
				)}
			</Stack>
		</Paper>
	);
}

const Dot: React.FunctionComponent<IDotProps> = ({ active, cx, cy, payload, stroke }) => {
	if(active && payload?.name === active) {
		return <circle cx={cx} cy={cy} fill={stroke} r={6} />;
	}
	return null;
}

export { ChartTooltip, ChartTooltipContent, Dot };
