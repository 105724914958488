import { createBrowserRouter, RouterProvider, RouteObject } from 'react-router-dom';
import Home, { HomePath } from './home';

export interface Route {
	path: string;
	element: JSX.Element;
}

export const Routes: Route[] = [
	{ path: HomePath, element: <Home /> },
];

function getRoutes(routes: Route[]): RouteObject[] {
	let data: RouteObject[] = [];
	for(let route of routes) {
		data.push(route);
	}
	return data;
}

export const PageRouter: React.FunctionComponent = () => {
	const routes = getRoutes(Routes);
	return <RouterProvider router={createBrowserRouter(routes)} />
}

export default PageRouter;
