import React from 'react';
import { Box, Stack, Typography, Link } from '@mui/material';

interface IProps {
	title: string;
	href?: string;
	alert?: React.ReactNode;
	children?: React.ReactNode;
}

const WrapperStyle = {
	'--Content-margin': '0 auto',
	'--Content-maxWidth': 'var(--mui-maxWidth-xl)',
	'--Content-paddingX': '24px',
	'--Content-padding': 'var(--Content-paddingY) var(--Content-paddingX)',
	'--Content-width': '100%',

	display: 'flex auto',
	flex: '1 1 auto',
	flexDirection: 'column auto',
	userSelect: 'none',

	'@media(min-width: 0px)': {
		'--Content-paddingY': '24px',
	},

	'@media(min-width: 1200px)': {
		'--Content-paddingY': '45px',
	},
};

const WrapperInnerStyle = {
	maxWidth: 'var(--Content-maxWidth)',
	margin: 'var(--Content-margin)',
	padding: 'var(--Content-padding)',
	width: 'var(--Content-width)',
};

const WrapperStackStyle = {
	display: 'flex',
	flexDirection: 'column',
	gap: '32px',
};

const TitleStackStyle = {
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'stretch',

	'@media(max-width: 850px)': {
		'.MuiAlert-root': {
			display: 'none',
		}
	}
};

const TitleWrapperStyle = {
	flex: '1 1 auto',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
}

const WrapperTitleTextStyle = {
	margin: '0px',
	fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
	fontWeight: '500',
	fontSize: '2rem',
	lineHeight: '1.2',
};

const LogoLink: React.FunctionComponent<{ title: string }> = ({ title }) => (
	<Typography sx={WrapperTitleTextStyle} variant={'h4'}>{title}</Typography>
)

const LogoText: React.FunctionComponent<{ title: string, href: string }> = ({ title, href }) => (
	<Link sx={{ textDecoration: 'none', color: 'inherit' }} href={href ?? '/'}>
		<Typography sx={WrapperTitleTextStyle} variant={'h4'}>{title}</Typography>
	</Link>
)

export const DashboardContent: React.FunctionComponent<IProps> = (props) => (
	<Box sx={WrapperStyle} component={'main'}>
		<Box sx={WrapperInnerStyle}>
			<Stack sx={WrapperStackStyle}>
				<Stack sx={TitleStackStyle}>
					<Box sx={TitleWrapperStyle}>
						{ props.href ? <LogoText title={props.title} href={props.href} /> : <LogoLink title={props.title} /> }
						{ props.alert }
					</Box>
				</Stack>
				{props.children}
			</Stack>
		</Box>
	</Box>
);

export default DashboardContent;
