import { observable, action, runInAction, makeAutoObservable } from 'mobx';
import BackendAPI, { IStats, IProfitableItem } from 'src/services/backend';

interface IHiddenItem {
	itemId: number;
	expiresAt: Date;
}

const HiddenItemTTL = 4 * 60 * 60 * 1000; // 4 hours
export default class RunescapeStore {
	@observable public stats?: IStats;
	@observable public items: IProfitableItem[] = [];
	@observable public hidden: number[] = [];
	private interval?: NodeJS.Timeout;
	
	public constructor() {
		this.loadHiddenItems();
		makeAutoObservable(this);
	}

	@action
	public start() {
		this.poll();
		if(!this.interval) {
			this.interval = setInterval(() => this.poll(), 15 * 1000);
		}
	}

	@action
	public hideItem(itemId: number) {
		const hidden = this.loadHiddenItems();
		hidden.push({ itemId, expiresAt: new Date(Date.now() + HiddenItemTTL) });
		this.saveHiddenItems(hidden);
	}

	@action
	public loadHiddenItems(): IHiddenItem[] {
		const data = JSON.parse(localStorage.getItem('hidden_items') || '[]') as IHiddenItem[];
		const hiddenItems = data.filter(item => new Date(item.expiresAt) > new Date());
		this.hidden = hiddenItems.map(item => item.itemId);
		return hiddenItems;
	}

	@action
	public saveHiddenItems(data: IHiddenItem[]) {
		localStorage.setItem('hidden_items', JSON.stringify(data));
		this.hidden = data.map(item => item.itemId);
	}

	@action
	public poll() {
		Promise.all([
			BackendAPI.GetStats(),
			BackendAPI.GetProfitableItems(),
		]).then(([stats, items]) => {
			runInAction(() => {
				this.stats = stats;
				this.items = items;
				this.loadHiddenItems();
			});
		}).catch((err: Error) => {
			console.error(err);
			if(this.interval) {
				clearInterval(this.interval);
				this.interval = undefined;
			}
		});
	}
}
