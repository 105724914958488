import { PaletteColorOptions, Shadows, experimental_extendTheme as extendTheme } from "@mui/material";
import type {} from '@mui/material/themeCssVarsAugmentation';

declare module '@mui/material' {
	interface Color {
		950?: string;
	}
}

declare module '@mui/material/styles' {
	interface TypeBackground {
		secondary?: string;
	}

	interface ColorSystemOptions {
		shadows?: Shadows;
		spacing?: string;
		maxWidth?: {
			xs?: number | string;
			sm?: number | string;
			md?: number | string;
			lg?: number | string;
			xl?: number | string;
		};
	}

	interface PaletteOptions {
		divider?: string;
		neutral?: PaletteColorOptions;
		nav?: {
			title?: string;
		};
	}

	interface Palette {
		neutral?: PaletteColorOptions;
	}
}

export const ColorScheme = extendTheme({
	colorSchemes: {
		light: {
			spacing: '8px',
			maxWidth: {
				xs: 0,
				sm: '600px',
				md: '900px',
				lg: '1200px',
				xl: '1440px',
			},
			shadows: [
				'none',
				'0px 1px 2px rgba(0, 0, 0, 0.08)',
				'0px 1px 5px rgba(0, 0, 0, 0.08)',
				'0px 1px 8px rgba(0, 0, 0, 0.08)',
				'0px 1px 10px rgba(0, 0, 0, 0.08)',
				'0px 1px 14px rgba(0, 0, 0, 0.08)',
				'0px 1px 18px rgba(0, 0, 0, 0.08)',
				'0px 2px 16px rgba(0, 0, 0, 0.08)',
				'0px 3px 14px rgba(0, 0, 0, 0.08)',
				'0px 3px 16px rgba(0, 0, 0, 0.08)',
				'0px 4px 18px rgba(0, 0, 0, 0.08)',
				'0px 4px 20px rgba(0, 0, 0, 0.08)',
				'0px 5px 22px rgba(0, 0, 0, 0.08)',
				'0px 5px 24px rgba(0, 0, 0, 0.08)',
				'0px 5px 26px rgba(0, 0, 0, 0.08)',
				'0px 6px 28px rgba(0, 0, 0, 0.08)',
				'0px 6px 30px rgba(0, 0, 0, 0.08)',
				'0px 6px 32px rgba(0, 0, 0, 0.08)',
				'0px 7px 34px rgba(0, 0, 0, 0.08)',
				'0px 7px 36px rgba(0, 0, 0, 0.08)',
				'0px 8px 38px rgba(0, 0, 0, 0.08)',
				'0px 8px 40px rgba(0, 0, 0, 0.08)',
				'0px 8px 42px rgba(0, 0, 0, 0.08)',
				'0px 9px 44px rgba(0, 0, 0, 0.08)',
				'0px 9px 46px rgba(0, 0, 0, 0.08)',
			],
			palette: {
				divider: 'var(--mui-palette-neutral-200)',
				nav: {
					title: 'var(--mui-palette-neutral-600)',
				},
				action: {
					hover: 'rgba(0, 0, 0, 0.04)',
				},
				background: {
					default: 'var(--mui-palette-common-white)',
					secondary: 'var(--mui-palette-neutral-50)',
				},
				text: {
					primary: 'var(--mui-palette-neutral-900)',
					primaryChannel: '33 38 54',
					secondary: 'var(--mui-palette-neutral-500)',
					secondaryChannel: '102 112 133',
					disabled: 'var(--mui-palette-neutral-400)',
				},
				common: {
					black: '#000000',
					white: '#ffffff',
					background: '#fff',
					onBackground: '#000',
					backgroundChannel: '255 255 255',
					onBackgroundChannel: '0 0 0',
				},
				primary: {
					50: '#ecf0ff',
					100: '#dde3ff',
					200: '#c2cbff',
					300: '#9ca7ff',
					400: '#7578ff',
					500: '#635bff',
					600: '#4e36f5',
					700: '#432ad8',
					800: '#3725ae',
					900: '#302689',
					950: '#1e1650',
					light: '#7578ff',
					main: '#635bff',
					dark: '#4e36f5',
					contrastText: '#ffffff',
				},
				secondary: {
					50: '#fbfcfe',
					100: '#f0f4f8',
					200: '#dde7ee',
					300: '#cdd7e1',
					400: '#9fa6ad',
					500: '#636b74',
					600: '#555e68',
					700: '#32383e',
					800: '#202427',
					900: '#121517',
					950: '#090a0b',
					light: '#555e68',
					main: '#32383e',
					dark: '#202427',
				},
				grey: {
					50: '#fafafa',
					100: '#f5f5f5',
					200: '#eeeeee',
					300: '#e0e0e0',
					400: '#bdbdbd',
					500: '#9e9e9e',
					600: '#757575',
					700: '#616161',
					800: '#424242',
					900: '#212121',
					A100: '#f5f5f5',
					A200: '#eeeeee',
					A400: '#bdbdbd',
					A700: '#616161',
				},
				neutral: {
					50: '#f9fafb',
					100: '#f1f1f4',
					200: '#dcdfe4',
					300: '#b3b9c6',
					400: '#8a94a6',
					500: '#667085',
					600: '#565e73',
					700: '#434a60',
					800: '#313749',
					900: '#212636',
					950: '#121621',
				},
				info: {
					50: '#ecfdff',
					100: '#cff7fe',
					200: '#a4eefd',
					300: '#66e0fa',
					400: '#10bee8',
					500: '#04aad6',
					600: '#0787b3',
					700: '#0d6d91',
					800: '#145876',
					900: '#154964',
					950: '#082f44',
					light: '#10bee8',
					main: '#04aad6',
					dark: '#0787b3',
					contrastText: '#ffffff',
				},
				success: {
					50: '#f0fdfa',
					100: '#ccfbef',
					200: '#9af5e1',
					300: '#5fe9ce',
					400: '#2ed3b8',
					500: '#15b79f',
					600: '#0e9382',
					700: '#107569',
					800: '#115e56',
					900: '#134e48',
					950: '#042f2c',
					light: '#2ed3b8',
					main: '#15b79f',
					dark: '#0e9382',
					contrastText: '#ffffff',
				},
				warning: {
					50: '#fffaea',
					100: '#fff3c6',
					200: '#ffe587',
					300: '#ffd049',
					400: '#ffbb1f',
					500: '#fb9c0c',
					600: '#de7101',
					700: '#b84d05',
					800: '#953b0b',
					900: '#7b310c',
					950: '#471701',
					light: '#ffbb1f',
					main: '#fb9c0c',
					dark: '#de7101',
					contrastText: '#ffffff',
				},
				error: {
					50: '#fef3f2',
					100: '#fee4e2',
					200: '#ffcdc9',
					300: '#fdaaa4',
					400: '#f97970',
					500: '#f04438',
					600: '#de3024',
					700: '#bb241a',
					800: '#9a221a',
					900: '#80231c',
					950: '#460d09',
					light: '#f97970',
					main: '#f04438',
					dark: '#de3024',
					contrastText: '#ffffff',
				},
				Avatar: {
					defaultBg: 'var(--mui-palette-neutral-600)',
				},
			},
		},
		dark: {
			spacing: '8px',
			shadows: [
				'none',
				'0px 1px 2px rgba(0, 0, 0, 0.5)',
				'0px 1px 5px rgba(0, 0, 0, 0.5)',
				'0px 1px 8px rgba(0, 0, 0, 0.5)',
				'0px 1px 10px rgba(0, 0, 0, 0.5)',
				'0px 1px 14px rgba(0, 0, 0, 0.5)',
				'0px 1px 18px rgba(0, 0, 0, 0.5)',
				'0px 2px 16px rgba(0, 0, 0, 0.5)',
				'0px 3px 14px rgba(0, 0, 0, 0.5)',
				'0px 3px 16px rgba(0, 0, 0, 0.5)',
				'0px 4px 18px rgba(0, 0, 0, 0.5)',
				'0px 4px 20px rgba(0, 0, 0, 0.5)',
				'0px 5px 22px rgba(0, 0, 0, 0.5)',
				'0px 5px 24px rgba(0, 0, 0, 0.5)',
				'0px 5px 26px rgba(0, 0, 0, 0.5)',
				'0px 6px 28px rgba(0, 0, 0, 0.5)',
				'0px 6px 30px rgba(0, 0, 0, 0.5)',
				'0px 6px 32px rgba(0, 0, 0, 0.5)',
				'0px 7px 34px rgba(0, 0, 0, 0.5)',
				'0px 7px 36px rgba(0, 0, 0, 0.5)',
				'0px 8px 38px rgba(0, 0, 0, 0.5)',
				'0px 8px 40px rgba(0, 0, 0, 0.5)',
				'0px 8px 42px rgba(0, 0, 0, 0.5)',
				'0px 9px 44px rgba(0, 0, 0, 0.5)',
				'0px 9px 46px rgba(0, 0, 0, 0.5)',
			],
			palette: {
				divider: 'var(--mui-palette-neutral-700)',
				nav: {
					title: 'var(--mui-palette-neutral-400)',
				},
				action: {
					hover: 'rgba(255, 255, 255, 0.04)',
				},
				background: {
					default: 'var(--mui-palette-neutral-950)',
					secondary: 'var(--mui-palette-neutral-900)',
					paper: 'var(--mui-palette-neutral-900)',
				},
				text: {
					primary: 'var(--mui-palette-neutral-100)',
					primaryChannel: '240 244 248',
					secondary: 'var(--mui-palette-neutral-400)',
					secondaryChannel: '159 166 173',
					disabled: 'var(--mui-palette-neutral-600)',
				},
				common: {
					black: '#000000',
					white: '#ffffff',
					background: '#000',
					onBackground: '#fff',
					backgroundChannel: '0 0 0',
					onBackgroundChannel: '255 255 255',
				},
				primary: {
					50: '#ecf0ff',
					100: '#dde3ff',
					200: '#c2cbff',
					300: '#9ca7ff',
					400: '#7578ff',
					500: '#635bff',
					600: '#4e36f5',
					700: '#432ad8',
					800: '#3725ae',
					900: '#302689',
					950: '#1e1650',
					light: '#9ca7ff',
					main: '#7578ff',
					dark: '#635bff',
					mainChannel: '117 120 255',
					lightChannel: '156 167 255',
					darkChannel: '99 91 255',
				},
				secondary: {
					50: '#fbfcfe',
					100: '#f0f4f8',
					200: '#dde7ee',
					300: '#cdd7e1',
					400: '#9fa6ad',
					500: '#636b74',
					600: '#555e68',
					700: '#32383e',
					800: '#202427',
					900: '#121517',
					950: '#090a0b',
					light: '#f0f4f8',
					main: '#dde7ee',
					dark: '#cdd7e1',
					mainChannel: '221 231 238',
					lightChannel: '240 244 248',
					darkChannel: '205 215 225',
				},
				grey: {
					50: '#fafafa',
					100: '#f5f5f',
					200: '#eeeee',
					300: '#e0e0e',
					400: '#bdbdb',
					500: '#9e9e9',
					600: '#75757',
					700: '#61616',
					800: '#42424',
					900: '#212121',
					A100: '#f5f5f5',
					A200: '#eeeeee',
					A400: '#bdbdbd',
					A700: '#616161',
				},
				neutral: {
					50: '#fbfcfe',
					100: '#f0f4f8',
					200: '#dde7ee',
					300: '#cdd7e1',
					400: '#9fa6ad',
					500: '#636b74',
					600: '#555e68',
					700: '#32383e',
					800: '#202427',
					900: '#121517',
					950: '#090a0b',
				},
				info: {
					50: '#ecfdff',
					100: '#cff7fe',
					200: '#a4eefd',
					300: '#66e0fa',
					400: '#10bee8',
					500: '#04aad6',
					600: '#0787b3',
					700: '#0d6d91',
					800: '#145876',
					900: '#154964',
					950: '#082f44',
					light: '#66e0fa',
					main: '#10bee8',
					dark: '#04aad6',
					mainChannel: '16 190 232',
					lightChannel: '102 224 250',
					darkChannel: '4 170 214',
				},
				success: {
					50: '#f0fdfa',
					100: '#ccfbef',
					200: '#9af5e1',
					300: '#5fe9ce',
					400: '#2ed3b8',
					500: '#15b79f',
					600: '#0e9382',
					700: '#107569',
					800: '#115e56',
					900: '#134e48',
					950: '#042f2c',
					light: '#5fe9ce',
					main: '#2ed3b8',
					dark: '#15b79f',
					mainChannel: '46 211 184',
					lightChannel: '95 233 206',
					darkChannel: '21 183 159',
				},
				warning: {
					50: '#fffaea',
					100: '#fff3c6',
					200: '#ffe587',
					300: '#ffd049',
					400: '#ffbb1f',
					500: '#fb9c0c',
					600: '#de7101',
					700: '#b84d05',
					800: '#953b0b',
					900: '#7b310c',
					950: '#471701',
					light: '#ffd049',
					main: '#ffbb1f',
					dark: '#fb9c0c',
				},
				error: {
					50: '#fef3f2',
					100: '#fee4e2',
					200: '#ffcdc9',
					300: '#fdaaa4',
					400: '#f97970',
					500: '#f04438',
					600: '#de3024',
					700: '#bb241a',
					800: '#9a221a',
					900: '#80231c',
					950: '#460d09',
					light: '#fdaaa4',
					main: '#f97970',
					dark: '#f04438',
					mainChannel: '249 121 112',
					lightChannel: '253 170 164',
					darkChannel: '240 68 56',
				},
				Avatar: {
					defaultBg: 'var(--mui-palette-neutral-200)',
				},
				Chip: {
					defaultBorder: 'var(--mui-palette-neutral-500)',
				},
			},
		},
	}
});

export default ColorScheme;
