import API from './api';
import Config from './config';

export interface IClientIntegrity {
	token: string;
}

export interface IStats {
	items: number;
	profit: number;
	cost: number;
}

export interface IProfitableItem {
	item: IItem;
	low: IAuctionData;
	high: IAuctionData;
	daily_volume: number;
	profit_low: number;
	profit_high: number;
}

export interface IChartData {
	item: IItem;
	low: IAuctionData;
	high: IAuctionData;
	timeseries: ITimeseries[];
	daily_volume: number;
}

export interface IItem {
	id: number;
	name: string;
	examine: string;
	icon: string;
	limit: number;
	value: number;
	lowalch: number;
	highalch: number;
	members: boolean;
}

export interface ITimeseries {
	low: IAuctionData;
	high: IAuctionData;
	timestamp: string;
}

export interface IAuctionData {
	price: number;
	volume: number;
}

class BackendService extends API {
	constructor() {
		super({
			baseUrl: Config.api.baseUrl,
			headers: {
				'Content-Type': 'application/json',
			}
		});
	}

	public async ClientIntegrity(): Promise<void> {
		const res: IClientIntegrity = await this.post('/v1/security/integrity');
		this.headers['X-Integrity-Token'] = res.token;
	}

	public async GetStats(): Promise<IStats> {
		return this.get('/v1/profit/info');
	}

	public async GetProfitableItems(): Promise<IProfitableItem[]> {
		if(!this.headers['X-Integrity-Token']) {
			await this.ClientIntegrity();
		}
		return this.get('/v1/profit/items');
	}

	public async GetChartData(itemID: number): Promise<IChartData> {
		if(!this.headers['X-Integrity-Token']) {
			await this.ClientIntegrity();
		}
		return this.get(`/v1/charts/${itemID}`);
	}
}

const BackendAPI = new BackendService();
export default BackendAPI;
