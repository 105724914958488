import React from 'react';
import { Avatar, Card, CardContent, Stack, Typography } from '@mui/material';

interface IProps {
	icon: React.ReactNode;
	title: string;
	value: string;
}

const CardStyle = {
	'--Paper-shadow': 'var(--mui-shadows-1)',
	'--Paper-overlay': 'var(--mui-overlays-1)',
	backgroundColor: 'var(--mui-palette-background-paper)',
	color: 'var(--mui-palette-text-primary)',
	transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)',
	boxShadow: 'var(--Paper-shadow)',
	backgroundImage: 'none',
	overflow: 'hidden',
	borderRadius: '20px',

	'.MuiPaper-elevation1': {
		boxShadow: 'rgba(0, 0, 0, 0.24) 0px 5px 22px 0px, rgba(255, 255, 255, 0.12) 0px 0px 0px 1px',
	}
}

const StackStyle = {
	display: 'flex',
	flexDirection: 'row',
	gap: 'calc(3 * var(--mui-spacing))',
	alignItems: 'center',
}

const AvatarStyle = {
	'--Icon-fontSize': 'var(--icon-fontSize-md)',
	'--Avatar-size': '48px',
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	flexShrink: 0,
	fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
	lineHeight: 1,
	borderRadius: '50%',
	overflow: 'hidden',
	userSelect: 'none',
	fontSize: '0.875rem',
	fontWeight: 500,
	height: 'var(--Avatar-size, 40px)',
	letterSpacing: '0px',
	width: 'var(--Avatar-size, 40px)',
	backgroundColor: 'var(--mui-palette-background-paper)',
	boxShadow: 'var(--mui-shadows-8)',
	color: 'var(--mui-palette-text-primary)',
}

const HeaderTypographyStyle = {
	margin: 0,
	fontFamily: '"Inter",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji"',
	fontWeight: 400,
	fontSize: '1rem',
	lineHeight: 1.5,
	color: 'var(--mui-palette-text-secondary)',
}

const ValueTypographyStyle = {
	margin: '0px',
	fontFamily: '"Inter", -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
	fontWeight: 500,
	fontSize: '2.25rem',
	lineHeight: 1.2,
}

const Metric: React.FunctionComponent<IProps> = (props) => (
	<Card sx={CardStyle} elevation={1}>
		<CardContent sx={{ padding: '16px 24px 32px' }}>
			<Stack sx={StackStyle}>
				<Avatar sx={AvatarStyle} variant={'circular'}>{props.icon}</Avatar>
				<div>
					<Typography sx={HeaderTypographyStyle} variant={'body1'}>{props.title}</Typography>
					<Typography sx={ValueTypographyStyle} variant={'h3'}>{props.value}</Typography>
				</div>
			</Stack>
		</CardContent>
	</Card>
);
export default Metric;
