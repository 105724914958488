import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { zaraz } from 'zaraz-ts';
import { Grid, Card, Box, Typography, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Avatar, Paper, IconButton, Tooltip, CircularProgress, Alert, AlertColor, Badge } from '@mui/material';
import { AccountBalance, Backpack, Refresh, ShowChart, VisibilityOffOutlined } from '@mui/icons-material';
import { ResponsiveContainer, LineChart, CartesianGrid, XAxis, YAxis, Line } from 'recharts';
import BackendAPI, { IChartData, IProfitableItem } from 'src/services/backend';
import { ChartTooltip, ChartTooltipContent, Dot } from 'src/components/ChartTooltip';
import DashboardContent from 'src/components/Dashboard';
import Metric from 'src/components/Metric';
import store from 'src/store';

interface IItemTableProps {
	selectedItem?: number;
	items: IProfitableItem[];
	setSelectedItem: (id: number) => void;
}

interface IItemTableEntryProps {
	active?: boolean;
	data: IProfitableItem,
	setSelectedItem: () => void,
	addHiddenItem: () => void,
}

const AlertMessages: { level: AlertColor, message: string }[] = [
	{ level: 'info', message: 'Tip: Hidden items are automatically unhidden after 4 hours.' },
	{ level: 'info', message: 'Tip: Use a staff of fire to maximize your profit.' },
	{ level: 'info', message: 'Tip: Nature rune costs are taken into account when calculating profit.' },
	{ level: 'warning', message: 'Reminder: Macros are strictly prohibited in RuneScape!' },
	{ level: 'info', message: 'Tip: The best deals tend to be found late at night. (NA Hours)' },
]

const ChartLines = [
	{ name: 'Price', key: 'price', color: 'var(--mui-palette-primary-main)' },
	{ name: 'Volume', key: 'volume', color: 'var(--mui-palette-success-main)' },
]

const CardStyle = {
	'--Paper-shadow': 'var(--mui-shadows-1)',
	'--Paper-overlay': 'var(--mui-overlays-1)',
	display: 'flex',
	height: 'calc(100vh - 310px)',
	maxHeight: 'calc(100vh - 310px)',
	flexDirection: 'column',
	backgroundColor: 'var(--mui-palette-background-paper)',
	color: 'var(--mui-palette-text-primary)',
	transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1)',
	boxShadow: 'var(--Paper-shadow)',
	backgroundImage: 'none',
	overflow: 'hidden',
	borderRadius: '20px',

	'.MuiPaper-elevation1': {
		boxShadow: 'rgba(0, 0, 0, 0.24) 0px 5px 22px 0px, rgba(255, 255, 255, 0.12) 0px 0px 0px 1px',
	}
}

const ContentWrapperStyle = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	flexGrow: 1,
}

const ItemTableHeaderStyle = {
	'.MuiTableCell-root': {
		backgroundColor: 'var(--mui-palette-background-paper)',
	}
}

const ItemTableRowStyle = {
	'&:hover': {
		backgroundColor: 'var(--mui-palette-action-hover)',
	}
}

const ItemIconStyle = {
	height: 24,
	width: 24,

	'& .MuiAvatar-img': {
		objectFit: 'contain',
	}
}

const WebsiteAlert = () => {
	const [alert, setAlert] = useState(AlertMessages[Math.floor(Math.random() * AlertMessages.length)]);
	useEffect(() => {
		const interval = setInterval(() => setAlert(AlertMessages[Math.floor(Math.random() * AlertMessages.length)]), 10000);
		return () => clearInterval(interval);
	}, []);

	if(!alert) return <></>;
	return <Alert sx={{ zIndex: 1000 }} severity={alert.level}>{alert.message}</Alert>
}

const ItemIcon: React.FunctionComponent<{ icon: string; members?: boolean }> = ({ icon, members }) => {
	if(!members) {
		return <Avatar sx={{ ...ItemIconStyle, marginRight: 2 }} variant={'square'} src={icon} />
	}
	return (
		<Badge
			sx={{ marginRight: 2 }}
			anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
			overlap={'rectangular'}
			badgeContent={<Avatar sx={{ height: 12, width: 12 }} variant={'circular'} src={'/img/members.webp'} />}
		>
			<Avatar sx={ItemIconStyle} variant={'square'} src={icon} />
		</Badge>
	)
}

const ItemTableEntry: React.FunctionComponent<IItemTableEntryProps> = ({ active, data, setSelectedItem, addHiddenItem }) => {
	const price = data.low.price || data.high.price;
	const profit = data.low.price ? data.profit_high : data.profit_low;
	return (
		<TableRow sx={ItemTableRowStyle} selected={active}>
			<TableCell onClick={setSelectedItem}>
				<Box display={'flex'} alignItems={'center'}>
					<ItemIcon icon={data.item.icon} members={data.item.members} />
					<Typography>{data.item.name}</Typography>
				</Box>
			</TableCell>
			<TableCell onClick={setSelectedItem}>{data.item.limit ? data.item.limit.toLocaleString() : '-'}</TableCell>
			<TableCell onClick={setSelectedItem}>{price.toLocaleString()}</TableCell>
			<TableCell onClick={setSelectedItem}>{profit.toLocaleString()}</TableCell>
			<TableCell>
				<Tooltip title={'Hide Item'}>
					<IconButton size={'small'} onClick={addHiddenItem}>
						<VisibilityOffOutlined fontSize={'small'} />
					</IconButton>
				</Tooltip>
			</TableCell>
		</TableRow>
	)
}

const ChartLookback = 3 * 60 * 60000;
const SelectedItemDetails: React.FunctionComponent<{ itemId?: number }> = ({ itemId }) => {
	const [loading, setLoading] = useState(true);
	const [data, setItemData] = useState<IChartData>();
	useEffect(() => {
		if(!itemId) return;
		setLoading(true);
		BackendAPI.GetChartData(itemId).then((data) => {
			data.timeseries = data.timeseries.filter(({ timestamp }) => {
				return new Date(timestamp).getTime() >= Date.now() - ChartLookback;
			});
			setItemData(data);
			setLoading(false);
		});
	}, [itemId]);

	let maxPrice = 0;
	let maxVolume = 0;
	const graph = (data?.timeseries || []).map(({ low, high, timestamp }) => {
		maxPrice = Math.max(maxPrice, low.price, high.price);
		maxVolume = Math.max(maxVolume, low.volume, high.volume);
		return ({
			price: Math.max(low.price, high.price),
			volume: Math.max(low.volume, high.volume),
			timestamp: new Date(timestamp).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
		});
	});

	if(loading) return <CircularProgress />
	return (
		<ResponsiveContainer height={420} width={'100%'}>
			<LineChart data={graph} margin={{ top: 0, right: 20, bottom: 0, left: 20 }}>
				<CartesianGrid strokeDasharray={'2 4'} vertical={false} />
				<XAxis axisLine={false} dataKey={'timestamp'} interval={5} tickLine={false} type={'category'} />
				<YAxis axisLine={false} domain={[0, maxPrice*1.2]} hide type={'number'} yAxisId={0} />
				<YAxis axisLine={false} domain={[0, maxVolume*1.1]} hide type={'number'} yAxisId={1} />
				
				{ChartLines.map((line, index) => (
					<Line
						key={line.name}
						animationDuration={300}
						dataKey={line.key}
						dot={<Dot />}
						name={line.name}
						stroke={line.color}
						strokeDasharray={index === 0 ? '0' : '2 4'}
						strokeWidth={2}
						type={'bump'}
						yAxisId={index}
					/>
				))}
                <ChartTooltip animationDuration={50} content={<ChartTooltipContent />} cursor={false} />
			</LineChart>
		</ResponsiveContainer>
	)
}

const Statistics: React.FunctionComponent = observer(() => {
	const stats = store.runescape.stats;
	return (
		<Grid container spacing={4}>
			<Grid item xs={12} md={4}>
				<Metric icon={<Backpack />} title={'Profitable Items'} value={stats?.items ? stats?.items.toLocaleString() : '-'} />
			</Grid>
			<Grid item xs={12} md={4}>
				<Metric icon={<ShowChart />} title={'Profit (Per 4h)'} value={stats?.profit ? stats?.profit.toLocaleString() : '-'} />
			</Grid>
			<Grid item xs={12} md={4}>
				<Metric icon={<AccountBalance />} title={'Cost (Per 4h)'} value={stats?.cost ? stats?.cost.toLocaleString() : '-'} />
			</Grid>
		</Grid>
	)
})

const ItemTable: React.FunctionComponent<IItemTableProps> = ({ items, selectedItem, setSelectedItem }) => (
	<TableContainer component={Paper} sx={{ overflow: 'auto', minHeight: '100%' }}>
		<Table stickyHeader>
			<TableHead sx={ItemTableHeaderStyle}>
				<TableRow>
					<TableCell>Item</TableCell>
					<TableCell>Buy Limit</TableCell>
					<TableCell>Cost</TableCell>
					<TableCell>Profit</TableCell>
					<TableCell>
						<Tooltip title={'Refresh'}>
							<IconButton size={'small'} onClick={() => store.runescape.poll()}>
								<Refresh fontSize={'small'} />
							</IconButton>
						</Tooltip>
					</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{items.map((data) => {
					const eventData = {
						itemId: data.item.id,
						itemName: data.item.name,
					};
					return (
						<ItemTableEntry
							key={data.item.id}
							data={data}
							active={data.item.id === selectedItem}
							setSelectedItem={() => {
								setSelectedItem(data.item.id);
								zaraz.track('ItemSelected', eventData);
							}}
							addHiddenItem={() => {
								store.runescape.hideItem(data.item.id);
								zaraz.track('ItemHidden', eventData);
							}}
						/>
					)
				})}
			</TableBody>
		</Table>
	</TableContainer>
)

export const HomePath = "/";
export const Home: React.FunctionComponent = observer(() => {
	const hiddenItems = store.runescape.hidden;
	const items = (store.runescape.items || []).filter(({ item }) => !hiddenItems.includes(item.id));

	const [selectedItem, setSelectedItem] = useState<number>();
	if(items.length > 0 && !selectedItem) setSelectedItem(items[0].item.id);
	return (
		<DashboardContent title={'RuneExchange'} alert={<WebsiteAlert />}>
			<Statistics />
			<Grid container spacing={4}>
				<Grid item xs={12} md={6}>
					<Card sx={CardStyle} elevation={1}>
						<Box sx={ContentWrapperStyle}>
							<SelectedItemDetails itemId={selectedItem} />
						</Box>
					</Card>
				</Grid>
				<Grid item xs={12} md={6}>
					<Card sx={CardStyle} elevation={1}>
						<Box sx={{ ...ContentWrapperStyle, height: '500px', display: 'flex', flexDirection: 'column' }}>
							<ItemTable items={items} selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
						</Box>
					</Card>
				</Grid>
			</Grid>
		</DashboardContent>
	);
})

export default Home;
